import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { RuleSet } from '../../Services/Embeddedbi.types';


interface RuleSetProps {
	ruleSet: RuleSet;
	setRuleSet: (ruleSet: RuleSet, index: number) => void;
	removeRuleSet: (index: number) => void;
	index: number;
}

export default function RuleSetFields({ruleSet, setRuleSet, removeRuleSet, index}: RuleSetProps) {
	return (
		<div className="flex flex-direction-column" data-testid="dashboard-rule-set">
			<div className="flex flex-end">
				<div className="textOnlyButton" onClick={() => removeRuleSet(index)} data-testid="dashboard-rule-set-remove">x</div>
			</div>
			<FormControlLabel
				control={<Checkbox className="mr-8" checked={ruleSet.financialVisibility} onChange={() => setRuleSet({ ...ruleSet, financialVisibility: !ruleSet.financialVisibility }, index)} />}
				label="Financial Visibility"
				data-testid="dashboard-rule-set-financial" />
			<FormControlLabel
				control={<Checkbox className="mr-8" checked={ruleSet.costProfitContract} onChange={() => setRuleSet({ ...ruleSet, costProfitContract: !ruleSet.costProfitContract }, index)} />}
				label="Cost and Profit Contracts"
				data-testid="dashboard-rule-set-contracts" />
			<FormControlLabel
				control={<Checkbox className="mr-8" checked={ruleSet.costProfitJob} onChange={() => setRuleSet({ ...ruleSet, costProfitJob: !ruleSet.costProfitJob }, index)} />}
				label="Cost and Profit Jobs"
				data-testid="dashboard-rule-set-jobs" />
			<FormControlLabel
				control={<Checkbox className="mr-8" checked={ruleSet.costProfitGlobal} onChange={() => setRuleSet({ ...ruleSet, costProfitGlobal: !ruleSet.costProfitGlobal }, index)} />}
				label="Global Cost and Profit"
				data-testid="dashboard-rule-set-global" />
			<FormControlLabel
				control={<Checkbox className="mr-8" checked={ruleSet.adminWithFinancialVisibility} onChange={() => setRuleSet({ ...ruleSet, adminWithFinancialVisibility: !ruleSet.adminWithFinancialVisibility }, index)} />}
				label="Admin with Financial Visibility"
				data-testid="dashboard-rule-set-admin-financial" />
		</div>
	);
}
