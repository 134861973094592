import React, { useState } from 'react';
import { Router, Route } from 'react-router-dom';

import history from '../History';
import Header from '../Components/Header';
import CategoryList from '../Containers/CategoryList';
import CreateCategory from '../Containers/CreateCategory';
import ReorderCategories from '../Containers/ReorderCategories';
import CreateDashboard from '../Containers/CreateDashboard';
import { useUser } from '../User';

import RedirectCallbackContext from '../Contexts/RedirectCallbackContext';
import ReorderDashboards from '../Containers/ReorderDashboards';

export default function Routes() {
	const [pageTitle, setPageTitle] = useState('');
	const [redirectProps, setRedirectProps] = useState<any>();
	const { doSignOut } = useUser();

	function onClickEditRedirectHandler(redirectURL: string, redirectProps?: any) {
		setRedirectProps(redirectProps);
		history.push(redirectURL);
	}

	return (
		<div data-testid="root-app">
			<RedirectCallbackContext.Provider value={(redirectURL, redirectProps) => onClickEditRedirectHandler(redirectURL, redirectProps)}>
				<Router history={history}>
					<Header pageTitle={pageTitle} logOut={doSignOut} />
					<Route exact path="/">
						<CategoryList setPageTitle={(newTitle) => setPageTitle(newTitle)} />
					</Route>
					<Route path="/category/create">
						<CreateCategory setPageTitle={(newTitle) => setPageTitle(newTitle)} />
					</Route>
					<Route path="/category/edit">
						<CreateCategory setPageTitle={(newTitle) => setPageTitle(newTitle)} gotCategoryDetails={redirectProps} />
					</Route>
					<Route path="/category/list/reorder">
						<ReorderCategories setPageTitle={(newTitle) => setPageTitle(newTitle)} categoryList={redirectProps} />
					</Route>
					<Route path="/dashboard/create">
						<CreateDashboard setPageTitle={(newTitle) => setPageTitle(newTitle)} />
					</Route>
					<Route path="/dashboard/edit">
						<CreateDashboard setPageTitle={(newTitle) => setPageTitle(newTitle)} dashboardId={redirectProps} />
					</Route>
					<Route path="/dashboard/list/reorder">
						<ReorderDashboards setPageTitle={(newTitle) => setPageTitle(newTitle)} category={redirectProps} />
					</Route>
				</Router>
			</RedirectCallbackContext.Provider>
		</div>
	);
}
