import React from 'react';
import ReactDOM from 'react-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import App from './App';
import { UserProvider } from './User';
import { BUGSNAG_API_KEY } from './Config/Config';

Bugsnag.start({
	apiKey: BUGSNAG_API_KEY,
	plugins: [new BugsnagPluginReact()],
	enabledReleaseStages: [ 'production', 'staging' ],
});

const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React);

const children = (
	<UserProvider>
		<App />
	</UserProvider>
);

ReactDOM.render(
	ErrorBoundary ? (
		<ErrorBoundary>{children}</ErrorBoundary>
	) : (
		children
	),
	document.getElementById('root')
);
