import React from 'react';
import Select from 'react-select';
import TitledToggleSwitch from '../TitledToggleSwitch';
import { CategoryDetails } from '../../Containers/CreateCategory/CreateCategory.types';
import { SelectOption } from '../Filter/BaseFilterFields/BaseFilterFields';
import RichTextEditor from '../RichTextEditor';

interface CategoryFields {
	categoryDetails: CategoryDetails,
	setters: {
		title: (newTitle: string) => void;
		description: (newDescription: string) => void;
		modules: (newModule: string[]) => void;
		status: (newStatus: boolean) => void;
	}
}

const acceloModules = [
	{value: 'activity', label: 'Activities'},
	{value: 'asset', label: 'Assets'},
	{value: 'company', label: 'Companies'},
	{value: 'contact', label: 'Contacts'},
	{value: 'contract', label: 'Contracts'},
	{value: 'contract_period', label: 'Contract Periods'},
	{value: 'deployment', label: 'Deployments'},
	{value: 'expense', label: 'Expenses'},
	{value: 'invoice', label: 'Invoices'},
	{value: 'issue', label: 'Issues'},
	{value: 'job', label: 'Jobs'},
	{value: 'material', label: 'Materials'},
	{value: 'milestone', label: 'Milestones'},
	{value: 'prospect', label: 'Prospects'},
	{value: 'purchase', label: 'Purchases'},
	{value: 'quote', label: 'Quotes'},
	{value: 'task', label: 'Tasks'},
	{value: 'user', label: 'Users'},
]

function getSelectedModuleOptions(selectedModules: string[]) {
	const acceloModulesByValue = acceloModules.reduce((map: any, acceloModule) => {
		map[acceloModule.value] = acceloModule;
		return map;
  }, {});
  const selectedModuleOptions = selectedModules.map(selectedModule => acceloModulesByValue[selectedModule]);
  return selectedModuleOptions;
}

export default function CategoryFields({categoryDetails, setters} : CategoryFields) {
	function setSelectedModuleOptions(options: SelectOption[] | null) {
		if (options === null) {
			setters.modules([]);
		}
		else {
			const selectedModuleOptions = options.map(option => option.value);
			setters.modules(selectedModuleOptions);
		}
	}

	return (
		<div className="fullwidth">
			<div className="mb-16">
				<h4 className="mb-8">Title</h4>
				<input
					className="ebi__input"
					value={categoryDetails.title.value}
					onChange={(e) => setters.title(e.target.value)}
					onBlur={(e) => setters.title(e.target.value.trim())}
					data-testid="create-category-title-input"
				/>
				{!categoryDetails.title.isValid && (
					<div data-testid="create-category-title-input-error">* please enter a value for Title</div>
				)}
			</div>
			<div className="mb-16">
				<h4 className="mb-8">Description</h4>
				<RichTextEditor 
					value={categoryDetails.description}
					onEditorChange={(content) => setters.description(content)}
				/>
			</div>
			<div className="mb-16" style={{maxWidth: '700px'}} data-testid="category-module-select">
				<h4 className="mb-8">Related Modules</h4>
				<Select
					isMulti
					options={acceloModules}
					value={getSelectedModuleOptions(categoryDetails.modules)}
					placeholder='Select Module...'
					onChange={(options) => setSelectedModuleOptions(options as SelectOption[])} />
			</div>
			<div className="flex align-items-center mb-16">
				<TitledToggleSwitch
					title="Active"
					value={categoryDetails.status}
					setValue={(newStatus) => setters.status(newStatus)}
				/>
			</div>
		</div>
	)
}