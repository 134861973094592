import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import ChipInput from 'material-ui-chip-input'
import { Tooltip, Checkbox, FormControlLabel } from '@material-ui/core';


import Embeddedbi from '../../Services/Embeddedbi';

import { regions, regionLabels } from '../../Utility/Constants';
import { DashboardDetails } from '../../Containers/CreateDashboard/CreateDashboard.types';
import { CategoryOption } from './DashboardFields.types';
import { getPrivateDashboardDeploymentsTooltip } from '../../Utility/Strings';
import './DashboardFields.scss';
import RichTextEditor from '../RichTextEditor';

interface DashboardDetailsDelegate {
	title: (newTitle: string) => void,
	isNew: (isNew: boolean) => void,
	description: (newDescription: string) => void,
	thumbnail: (newThumbnail: string) => void,
	categories: (newCategory: any) => void,
	dashboardRegionLinks: {
		region: (newRegion: any, index: number) => void,
		quicksightId: (quicksightId: string, index: number) => void,
	},
	isPrivate: (isPrivate: boolean) => void,
	deployments: (deployments: string[]) => void,
}

interface Props {
	dashboardDetails: DashboardDetails;
	setters: DashboardDetailsDelegate,
}

export default function DashboardFields({ dashboardDetails, setters }: Props) {
	const [isLoading, setIsLoading] = useState(true);

	const [availableCategoryOptions, setAvailableCategoryOptions] = useState<CategoryOption[]>([]);
	useEffect(() => {
		(async () => {
			try {
				const categories = await Embeddedbi.getAllCategories();

				const availableOptions: CategoryOption[] = categories.map(({ id, title }) => {
					return {
						value: id,
						label: title,
					};
				});
				setAvailableCategoryOptions(availableOptions);
				setIsLoading(false);
			}
			catch {
				setIsLoading(false);
				alert('Something went wrong while loading Categories, please reload the page.');
			}
		})();
	}, []);

	const [selectedCategoryOptions, setSelectedCategoryOptions] = useState<CategoryOption[]>([]);
	useEffect(() => {
		const selectedOptions: CategoryOption[] = [];
		availableCategoryOptions.forEach((availableOption) => {
			if (dashboardDetails.categories.value.some((categoryId) => categoryId === availableOption.value)) {
				selectedOptions.push(availableOption);
			}
		});
		setSelectedCategoryOptions(selectedOptions);
	}, [availableCategoryOptions, dashboardDetails.categories.value]);

	function setDashboardIsPrivate(event: any) {
		setters.isPrivate(event.target.value === 'private');
	}

	function handleAddAllowedDeployment(deployment: string) {
		setters.deployments(dashboardDetails.deployments.concat(deployment));
	}

	function handleDeleteAllowedDeployment(deployment: string, index: number) {
		setters.deployments(dashboardDetails.deployments.filter((value, deploymentIndex) => deploymentIndex !== index));
	}

	return (
		<div>
			<div className="mb-16">
				<h4 className="mb-8">Title</h4>
				<input
					className="ebi__input"
					value={dashboardDetails.title.value}
					onChange={(e) => setters.title(e.target.value)}
					onBlur={(e) => setters.title(e.target.value.trim())}
					data-testid="create-dashboard-title-input" />
				{!dashboardDetails.title.isValid && (
					<div data-testid="create-dashboard-title-input-error">* please enter a value for Title</div>
				)}
				<div className="createDashboard__ruleSets">
					<FormControlLabel
						control={<Checkbox className="mr-8" checked={dashboardDetails.isNew} onChange={() => setters.isNew(!dashboardDetails.isNew)} />}
						label="Show “New” Badge"
						data-testid="dashboard-is-new-toggle" />
				</div>
			</div>
			<div className="mb-16">
				<h4 className="mb-8">Description</h4>
				<RichTextEditor
					onEditorChange={(content:string) => setters.description(content)}
					value={dashboardDetails.description || ''} />
			</div>
			<div className="mb-16">
				<h4 className="mb-8">Thumbnail</h4>
				<input
					className="ebi__input"
					value={dashboardDetails.thumbnail.value}
					onChange={(e) => setters.thumbnail(e.target.value)}
					onBlur={(e) => setters.thumbnail(e.target.value.trim())}
					data-testid="create-dashboard-thumbnail-input" />
				{!dashboardDetails.thumbnail.isValid && (
					<div data-testid="create-dashboard-thumbnail-input-error">* please enter a value for the thumbnail</div>
				)}
			</div>
			<div className="flex flex-direction-column mb-16" style={{ width: '100%', maxWidth: '700px' }}>
				<h4 className="mb-8">Dashboard visibility</h4>
				<div className="mb-8">
					<label className='dashboardVisibilityToggle mr-8'>
						<input
							type="radio"
							value={'public'}
							checked={!dashboardDetails.isPrivate}
							onChange={setDashboardIsPrivate}
							data-testid="create-dashboard-is-public" />
							Public
					</label>
					<Tooltip
						title={getPrivateDashboardDeploymentsTooltip(dashboardDetails.deployments)}
						disableHoverListener={dashboardDetails.isPrivate}>
						<label className='dashboardVisibilityToggle'>
							<input
								type="radio"
								value={'private'}
								checked={dashboardDetails.isPrivate}
								onChange={setDashboardIsPrivate}
								data-testid="create-dashboard-is-private" />
								Private
						</label>
					</Tooltip>
				</div>
				{dashboardDetails.isPrivate && <ChipInput
					className='dashboardDeployments'
					value={dashboardDetails.deployments}
					onAdd={(deployment) => handleAddAllowedDeployment(deployment)}
					onDelete={(deployment, index) => handleDeleteAllowedDeployment(deployment, index)}
					disableUnderline={true}
					blurBehavior='add'
					alwaysShowPlaceholder={true}
					placeholder='Press enter to add a deployment'
					data-testid="create-dashboard-deployments"
				/>}
			</div>
			<div className="mb-16" style={{ width: '100%', maxWidth: '700px' }} data-testid="create-dashboard-categories">
				<h4 className="mb-8">EBI Categories</h4>
				<Select
					isMulti
					options={availableCategoryOptions}
					value={selectedCategoryOptions}
					placeholder='Select Category...'
					onChange={(option) => {
						setters.categories(option);
					}}
					isLoading={isLoading} />
				{!dashboardDetails.categories.isValid && (
					<div data-testid="create-dashboard-category-input-error">
						* please enter at least 1 value for the related categories
					</div>)}
			</div>
			<div className="mb-16" style={{maxWidth: '700px'}}>
				<div className="flex space-between ">
					<h4 className="mb-8">Dashboard Links</h4>
					{/* TODO SPARK-116<div className="textOnlyButton" onClick={addOption}>+ add</div> */}
				</div>
				<div className="dashboardRegionLinks" data-testid="create-dashboard-links">
					{!!dashboardDetails.dashboardRegionLinks.value.length && dashboardDetails.dashboardRegionLinks.value.map((dashboardLink, index) => {
						const region = dashboardLink.region ? dashboardLink.region : regions[index];
						const regionLabel = regionLabels[region];
						return (
							<div className="p-16 mb-12 dashboardRegionLinks__container background-f9f9f9" key={index}>
								<h4 className="mb-8">{regionLabel} Region</h4>
								<input
									className="ebi__input ebi__input--white fullwidth"
									placeholder="Enter Quicksight Dashboard ID"
									onChange={(e) => setters.dashboardRegionLinks.quicksightId(e.target.value, index)}
									value={dashboardLink.quicksightId}
									data-testid="create-dashboard-link-id"/>
							</div>
						)}
					)}
					{!dashboardDetails.dashboardRegionLinks.isValid && (
						<div data-testid="create-dashboard-category-dashboard-link-error">
							* please ensure all dashboard links are valid
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
