import React, { useContext, useState } from 'react';

import './DashboardRow.scss';

import RedirectCallbackContext from '../../Contexts/RedirectCallbackContext';
import Embeddedbi from '../../Services/Embeddedbi';
import getStatusColor from '../../Utility/StatusColor';
import { Tooltip } from '@material-ui/core';
import { getPrivateDashboardDeploymentsTooltip } from '../../Utility/Strings';
import { useUser } from '../../User';
import getActiveClasses from '../../Utility/active-classes';


interface Props {
	dashboard: {
		id: string;
		title: string;
		status: string;
		isPrivate: boolean;
		deployments: string[];
	},
	setValuesHaveUpdated: () => void;
}

export default function DashboardRow({ dashboard, setValuesHaveUpdated }: Props) {
	const editDashboardRedirect = useContext(RedirectCallbackContext);
	const { user } = useUser();

	const [isLoading, setIsLoading] = useState(false);

	async function onPublishClick(dashboardId: string, dashboardStatus: string) {
		if (user?.isAdmin) {
			const currentDashboardStatus = getPublishStateText(dashboardStatus);
			if (window.confirm(`Are you sure you want to ${currentDashboardStatus} this dashboard?`)) {
				setIsLoading(true);
				try {
					await changeDashboardStatus(dashboardId, dashboardStatus);
					setValuesHaveUpdated();
					setIsLoading(false);
				}
				catch {
					setIsLoading(false);
					alert('Something went wrong while updating the dashboard status, please try again.');
				}
			}
		}
	}

	return (
		<div className="dashboard px-16 py-8">
			<div>
				<div className="dashboardRow__title"> {dashboard.title} </div>
			</div>
			<div style={{ color: '#808080', width: '100px' }}>
				{dashboard.isPrivate &&
					<Tooltip
						title={getPrivateDashboardDeploymentsTooltip(dashboard.deployments)}>
						<div className="dashboardRow__status"> Private </div>
					</Tooltip>
				}
			</div>
			<div>
				<div className="dashboardRow__status" style={{ color: getStatusColor(dashboard.status) }}> {dashboard.status} </div>
			</div>
			<div className="dashboard__RHS">
				<div className="dashboard__RHS__edit cta" onClick={() => editDashboardRedirect('/dashboard/edit', dashboard.id)} data-testid="test-dashboard-edit-button">{user?.isAdmin ? 'Edit' : 'View'}</div>
				<button
					className={getActiveClasses([
						'dashboard__RHS__button ml-16',
						{
							'dashboard__RHS__button--publish': dashboard.status !== 'active' && user?.isAdmin,
							'dashboard__RHS__button--unpublish': dashboard.status === 'active' && user?.isAdmin,
							'dashboard__RHS__button--disabled': !user?.isAdmin,
						}
					])}
					disabled={isLoading || !user?.isAdmin}
					onClick={() => onPublishClick(dashboard.id, dashboard.status)}
					data-testid="test-dashboard-publish-button">
					{getPublishStateText(dashboard.status)}
				</button>
			</div>
		</div >
	);
};

function getPublishStateText(status: string) {
	return status !== 'active' ? 'publish' : 'unpublish';
}

function getUpdatedDashboardStatus(status: string) {
	return status !== 'active' ? 'active' : 'inactive';
}

async function changeDashboardStatus(dashboardId: string, dashboardStatus: string) {
	const updatedDashboardStatus = getUpdatedDashboardStatus(dashboardStatus);
	await Embeddedbi.publishDashboard(dashboardId, updatedDashboardStatus);
}

export { getPublishStateText, getUpdatedDashboardStatus, changeDashboardStatus };