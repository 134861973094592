import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { UserLicenseRuleSet } from '../../Services/Embeddedbi.types';


interface UserLicenseRuleSetProps {
	userLicenseRuleSet: UserLicenseRuleSet;
	setUserLicenseRuleSet: (ruleSet: UserLicenseRuleSet) => void;
	removeUserLicenseRuleSet: () => void;
}

export default function RuleSetFields({ userLicenseRuleSet, setUserLicenseRuleSet, removeUserLicenseRuleSet }: UserLicenseRuleSetProps) {
	return (
		<div className="flex flex-direction-column" data-testid="dashboard-user-license-rule-set">
			<div className="flex flex-end">
				<div className="textOnlyButton" onClick={removeUserLicenseRuleSet} data-testid="dashboard-user-license-rule-set-remove">x</div>
			</div>
			<FormControlLabel
				control={<Checkbox className="mr-8" checked={userLicenseRuleSet.businessIntelligence} onChange={() => setUserLicenseRuleSet({ ...userLicenseRuleSet, businessIntelligence: !userLicenseRuleSet.businessIntelligence })} />}
				label="Business Intelligence"
				data-testid="dashboard-user-license-rule-set-business-intelligence" />
			<FormControlLabel
				control={<Checkbox className="mr-8" checked={userLicenseRuleSet.crm} onChange={() => setUserLicenseRuleSet({ ...userLicenseRuleSet, crm: !userLicenseRuleSet.crm })} />}
				label="Sales"
				data-testid="dashboard-user-license-rule-set-crm" />
			<FormControlLabel
				control={<Checkbox className="mr-8" checked={userLicenseRuleSet.projects} onChange={() => setUserLicenseRuleSet({ ...userLicenseRuleSet, projects: !userLicenseRuleSet.projects })} />}
				label="Projects"
				data-testid="dashboard-user-license-rule-set-projects" />
			<FormControlLabel
				control={<Checkbox className="mr-8" checked={userLicenseRuleSet.service} onChange={() => setUserLicenseRuleSet({ ...userLicenseRuleSet, service: !userLicenseRuleSet.service })} />}
				label="Service"
				data-testid="dashboard-user-license-rule-set-service" />
			<FormControlLabel
				control={<Checkbox className="mr-8" checked={userLicenseRuleSet.retainers} onChange={() => setUserLicenseRuleSet({ ...userLicenseRuleSet, retainers: !userLicenseRuleSet.retainers })} />}
				label="Retainers"
				data-testid="dashboard-user-license-rule-set-retainers" />
			<FormControlLabel
				control={<Checkbox className="mr-8" checked={userLicenseRuleSet.psa} onChange={() => setUserLicenseRuleSet({ ...userLicenseRuleSet, psa: !userLicenseRuleSet.psa })} />}
				label="ServOps"
				data-testid="dashboard-user-license-rule-set-psa" />
		</div>
	);
}