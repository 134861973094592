import React, { useState, useEffect, useContext } from 'react';
import RedirectCallbackContext from '../../Contexts/RedirectCallbackContext';
import { SavedDashboard, SavedCategory } from '../../Services/Embeddedbi.types';
import ReorderList from '../../Components/ReorderList';
import Embeddedbi from '../../Services/Embeddedbi';

interface ReorderDashboardsProps {
	setPageTitle: (newTitle: string) => void;
	category: SavedCategory;
}

export default function ReorderDashboards({ setPageTitle, category }: ReorderDashboardsProps) {
	const [isLoading, setIsLoading] = useState(false);
	const [initialCategory, setInitialCategory] = useState(category);
	const [categoryTitle, setCategoryTitle] = useState(category ? category.title : '');
	useEffect(() => setPageTitle(`Reorder ${categoryTitle} Dashboards`), [categoryTitle, setPageTitle]);
	const redirectCallback = useContext(RedirectCallbackContext);
	const searchParams = new URLSearchParams(window.location.search);
	const categoryId = searchParams.get('categoryId') || '';

	useEffect(() => {
		if (!(initialCategory && initialCategory.dashboards.length) && categoryId) {
			initializeDashboardList(categoryId);
		}
	}, [initialCategory, categoryId]);

	async function initializeDashboardList (id: string) {
		setIsLoading(true);
		const retrievedCategories = await Embeddedbi.getCategory(id);
		await setInitialCategory(retrievedCategories[0]);
		await setCategoryTitle(retrievedCategories[0].title);
		setIsLoading(false);
	}

	async function save(orderedList: SavedDashboard[]) {
		setIsLoading(true);
		try {
			await Embeddedbi.setCategoryDashboardOrder({orderedDashboards: orderedList, categoryId: categoryId});
			setIsLoading(false);
			redirectCallback('/');
		} catch {
			alert('Something went wrong with updating the ordering, please try again.');
		}
		setIsLoading(false);
	}

	return (
		<ReorderList
			initialList={initialCategory ? initialCategory.dashboards : []}
			isLoading={isLoading}
			listType='Dashboards'
			save={(orderedList) => save(orderedList as SavedDashboard[])}
			cancel={() => redirectCallback('/')} />
	)
}