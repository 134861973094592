import React, { useEffect, useState } from 'react';
import deepEqual from 'deep-equal';
import history from '../../History';

import Embeddedbi from '../../Services/Embeddedbi';
import { NewDashboard, SavedDashboard, RegionDashboard, UserLicenseRuleSet, RuleSet, FilterDetails, AccessLevelRuleSet, DashboardLink } from '../../Services/Embeddedbi.types';

import LoadingOverlay from '../../Components/LoadingOverlay';
import MetadataView from '../../Components/MetadataView';
import { Metadata } from '../../Components/MetadataView/MetadataView.types';
import { DashboardDetails } from './CreateDashboard.types';
import DashboardFields from '../../Components/DashboardFields';
import { isInvalidDateOffset, isDateRangeInvalid } from '../../Components/Filter/DateRangeFilterFields';
import UserLicenseRuleSetFields from '../../Components/UserLicenseRuleSetFields';
import RuleSetFields from '../../Components/RuleSetFields';
import AccessLevelFields from '../../Components/AccessLevelFields';
import { CategoryOption } from '../../Components/DashboardFields/DashboardFields.types';
import Filter from '../../Components/Filter';
import Parameters from '../../Components/DashboardParameters';
import './CreateDashboard.scss';
import { isSelectOptionsValid } from '../../Components/Filter/CustomSelectFilterFields';
import { useUser } from '../../User';
import { regions } from '../../Utility/Constants';

interface Props {
	setPageTitle: (newTitle: string) => void;
	dashboardId?: string;
}

export default function CreateDashboard({ setPageTitle, dashboardId }: Props) {
	const { user } = useUser();

	useEffect(() => {
		if (dashboardId) {
			setPageTitle('Edit Dashboard');
		} else {
			setPageTitle('Create Dashboard');
		}
	}, [setPageTitle, dashboardId]);

	const [isLoading, setIsLoading] = useState(true);

	const [gotDashboardDetails, setGotDashboardDetails] = useState<SavedDashboard>();
	const [dashboardDetails, setDashboardDetails] = useState<DashboardDetails>(getInitialDashboardDetails());
	const [dashboardMetadata, setDashboardMetadata] = useState<Metadata | undefined>();
	
	useEffect(() => {
		if (dashboardId) {
			(async () => {
				try {
					const dashboard = await Embeddedbi.getDashboard(dashboardId);
					setGotDashboardDetails(dashboard);
					setDashboardMetadata(dashboard);
					setDashboardDetails(getInitialDashboardDetails(dashboard));
					setIsLoading(false);
				}
				catch {
					setIsLoading(false);
					alert('Something went wrong while loading the dashboard, please try again.');
				}
			})();
		} else {
			setIsLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function setTitle(newTitle: string) {
		const isValidTitle = isTitleValid(newTitle);
		setDashboardDetails({ ...dashboardDetails, title: { value: newTitle, isValid: isValidTitle } });
	}

	function setIsNew(isNew: boolean) {
		setDashboardDetails({ ...dashboardDetails, isNew });
	}

	function setDescription(newDescription: string) {
		setDashboardDetails({ ...dashboardDetails, description: newDescription });
	}

	function setThumbnail(newThumbnail: string) {
		const isValidThumbnail = isThumbnailValid(newThumbnail);
		setDashboardDetails({ ...dashboardDetails, thumbnail: { value: newThumbnail, isValid: isValidThumbnail } });
	}

	function setCategories(newCategories: any) {
		const currentCategoryValue = newCategories === null ? [] : dashboardDetails.categories.value;
		const categories = newCategories && newCategories.target === undefined ? newCategories.map((categoryValue: CategoryOption) => categoryValue.value) : currentCategoryValue;
		const isValidCategories = isCategoriesValid(categories);
		setDashboardDetails({ ...dashboardDetails, categories: { value: categories, isValid: isValidCategories } });
	}

	function setDashboardRegion(newRegion: any, index: number) {
		let tempDashboards = dashboardDetails.dashboardRegionLinks.value;
		tempDashboards[index].region = newRegion.value;
		setDashboardDetails({ ...dashboardDetails, dashboardRegionLinks: { value: tempDashboards, isValid: true } });
	}

	function setQuicksightId(quicksightId: string, index: number) {
		let tempDashboards = dashboardDetails.dashboardRegionLinks.value;
		tempDashboards[index].quicksightId = quicksightId;
		setDashboardDetails({ ...dashboardDetails, dashboardRegionLinks: { value: tempDashboards, isValid: true } });
	}

	function setIsPrivate(isPrivate: boolean) {
		setDashboardDetails({ ...dashboardDetails, isPrivate });
	}

	function setDeployments(deployments: string[]) {
		setDashboardDetails({ ...dashboardDetails, deployments });
	}

	function addUserLicenseRuleSet() {
		const newRuleSet = [{
			businessIntelligence: false,
			crm: false,
			projects: false,
			service: false,
			retainers: false,
			psa: false,
		}];
		if (canAddUserLicenseRuleSet(dashboardDetails.userLicenseRuleSets.value)) {
			setDashboardDetails(prevState => {
				let tempRuleSets = prevState.userLicenseRuleSets.value;
				if (!tempRuleSets) {
					tempRuleSets = newRuleSet;
				}
				else {
					tempRuleSets = tempRuleSets.concat(newRuleSet);
				}
				return { ...prevState, userLicenseRuleSets: { value: tempRuleSets, isValid: true } };
			})
		}
		else {
			setDashboardDetails(prevState => { return { ...prevState, userLicenseRuleSets: { value: prevState.userLicenseRuleSets.value, isValid: false } } });
		}
	}

	function setUserLicenseRuleSet(modifiedUserLicenseRuleSet: UserLicenseRuleSet, index: number) {
		let tempRuleSets = dashboardDetails.userLicenseRuleSets.value;
		if (tempRuleSets) {
			tempRuleSets[index] = modifiedUserLicenseRuleSet;
			setDashboardDetails({ ...dashboardDetails, userLicenseRuleSets: { value: tempRuleSets, isValid: true } });
		}
	}

	function removeUserLicenseRuleSet(index: number) {
		let tempRuleSets = dashboardDetails.userLicenseRuleSets.value;
		if (tempRuleSets) {
			tempRuleSets = tempRuleSets.filter((ruleSet, ruleSetIndex) => ruleSetIndex !== index);
			setDashboardDetails({ ...dashboardDetails, userLicenseRuleSets: { value: tempRuleSets, isValid: true } });
		}
	}

	function addRuleSet() {
		const newRuleSet = [{
			financialVisibility: false,
			costProfitContract: false,
			costProfitJob: false,
			costProfitGlobal: false,
			adminWithFinancialVisibility: false,
		}];
		if (isRuleSetsValid(dashboardDetails.ruleSets.value)) {
			setDashboardDetails(prevState => {
				let tempRuleSets = prevState.ruleSets.value;
				if (!tempRuleSets) {
					tempRuleSets = newRuleSet;
				}
				else {
					tempRuleSets = tempRuleSets.concat(newRuleSet);
				}
				return { ...prevState, ruleSets: { value: tempRuleSets, isValid: true } };
			})
		}
		else {
			setDashboardDetails(prevState => { return { ...prevState, ruleSets: { value: prevState.ruleSets.value, isValid: false } } });
		}
	}

	function setRuleSet(modifiedRuleSet: RuleSet, index: number) {
		let tempRuleSet = dashboardDetails.ruleSets.value;
		if (tempRuleSet) {
			tempRuleSet[index] = modifiedRuleSet;
			setDashboardDetails({ ...dashboardDetails, ruleSets: { value: tempRuleSet, isValid: true } });
		}
	}

	function removeRuleSet(index: number) {
		let tempRuleSet = dashboardDetails.ruleSets.value;
		if (tempRuleSet && tempRuleSet.length > 1) {
			tempRuleSet = tempRuleSet.filter((ruleSet, ruleSetIndex) => ruleSetIndex !== index);
			setDashboardDetails({ ...dashboardDetails, ruleSets: { value: tempRuleSet, isValid: true } });
		}
		else {
			setDashboardDetails({ ...dashboardDetails, ruleSets: { value: [], isValid: true } });
		}
	}

	function addFilter() {
		const newFilter = {
			label: '',
			type: 'string',
			quicksightParameter: '',
		};
		if (isFiltersValid(dashboardDetails.filters.value)) {
			setDashboardDetails(prevState => {
				let tempFilters = [...prevState.filters.value];
				tempFilters.push(newFilter as FilterDetails);
				return { ...prevState, filters: { value: tempFilters, isValid: true } };
			})
		}
		else {
			setDashboardDetails(prevState => { return { ...prevState, filters: { value: prevState.filters.value, isValid: false } } });
		}
	}

	function setFilter(modifiedFilter: FilterDetails, index: number) {
		let newFilters = [...dashboardDetails.filters.value];
		newFilters[index] = modifiedFilter;
		setDashboardDetails({ ...dashboardDetails, filters: { value: newFilters, isValid: true } });
	}

	function removeFilter(index: number) {
		let existingFilters = [...dashboardDetails.filters.value];
		existingFilters = existingFilters.filter((filter, filterIndex) => filterIndex !== index);
		setDashboardDetails({ ...dashboardDetails, filters: { value: existingFilters, isValid: true } });
	}

	function setDashboardDetailsAreValid(): DashboardDetails {
		const updatedDashboardDetails = {
			...dashboardDetails,
			title: {
				value: dashboardDetails.title.value,
				isValid: isTitleValid(dashboardDetails.title.value),
			},
			description: dashboardDetails.description,
			thumbnail: {
				value: dashboardDetails.thumbnail.value,
				isValid: isThumbnailValid(dashboardDetails.thumbnail.value),
			},
			categories: {
				value: dashboardDetails.categories.value,
				isValid: isCategoriesValid(dashboardDetails.categories.value),
			},
			dashboardRegionLinks: {
				value: dashboardDetails.dashboardRegionLinks.value,
				isValid: isDashboardRegionLinksValid(dashboardDetails.dashboardRegionLinks.value),
			},
			ruleSets: {
				value: dashboardDetails.ruleSets.value || [],
				isValid: isRuleSetsValid(dashboardDetails.ruleSets.value),
			},
			filters: {
				value: dashboardDetails.filters.value,
				isValid: isFiltersValid(dashboardDetails.filters.value),
			},
		};
		setDashboardDetails(updatedDashboardDetails);
		return updatedDashboardDetails;
	}

	// TODO SPARK-116
	// function addOption() {
	// 	const newOption = [newDashboardLink]
	// 	if(!dashboardDetails.dashboardRegionLinks.value.some(dashboardLink => deepEqual(dashboardLink, newOption[0]))) {
	// 		setDashboardDetails(prevState => {
	// 			return {...prevState, dashboardRegionLinks: {value: prevState.dashboardRegionLinks.value.concat(newOption), isValid: true}};
	// 		})
	// 	}
	// }

	// TODO SPARK-159 - Reinstate deep equality check
	async function saveDashboard() {
		const updatedDashboardDetails = setDashboardDetailsAreValid();

		const invalidEntries = getInvalidEntries(updatedDashboardDetails);
		if (invalidEntries.length === 0) {
			setIsLoading(true);

			const saveData = buildSaveDataStructure(updatedDashboardDetails);
			try {
				if (gotDashboardDetails) {
					await Embeddedbi.editDashboard({ ...gotDashboardDetails, ...saveData });
				} else {
					await Embeddedbi.createDashboard(saveData);
				}
				setIsLoading(false);
				history.replace('/');
			}
			catch {
				setIsLoading(false);
				alert('Something went wrong while saving the dashboard, please try again.');
			}
		}
	}

	const setters = {
		title: (newTitle: string) => setTitle(newTitle),
		isNew: (isNew: boolean) => setIsNew(isNew),
		description: (newDescription: string) => setDescription(newDescription),
		thumbnail: (newThumbnail: string) => setThumbnail(newThumbnail),
		categories: (newCategory: any) => setCategories(newCategory),
		dashboardRegionLinks: {
			region: (newRegion: string | undefined, index: number) => setDashboardRegion(newRegion, index),
			quicksightId: (quicksightId: string, index: number) => setQuicksightId(quicksightId, index),
		},
		isPrivate: (isPrivate: boolean) => setIsPrivate(isPrivate),
		deployments: (deployments: string[]) => setDeployments(deployments),
	}

	return (
		<div className="createDashboard" data-testid="test-create-dashboard">
			{isLoading && <LoadingOverlay />}
			<div className="flex space-between">
				<div className="flex fullwidth">
					<div className="halfwidth p-16 createDashboard__borderedColumn">
						<DashboardFields dashboardDetails={dashboardDetails} setters={setters} />
						{gotDashboardDetails &&
							<div className="mb-16">
								<h4 className="mb-8">Status: {gotDashboardDetails.status}</h4>
							</div>
						}
						<Parameters parameterDetails={dashboardDetails.parameters} updateDashboardDetails={setDashboardDetails} />
						<div className="flex space-between">
							<h4 className="mb-8">Filters</h4>
							<div className="textOnlyButton" onClick={addFilter} data-testid="dashboard-add-filters">+ add</div>
						</div>
						{dashboardDetails.filters &&
							<div className="p-16 background-f9f9f9 createDashboard__filters">
								{dashboardDetails.filters.value.map((filter: FilterDetails, index: number) => (
									<Filter key={index} filterDetails={filter} setFilter={(filter: FilterDetails) => setFilter(filter, index)} removeFilter={() => removeFilter(index)} />
								))}
								{!dashboardDetails.filters.isValid && (<div data-testid="create-dashboard-filters-error">* please check your filters are populated and have unique QuickSight Parameters</div>)}
							</div>
						}
					</div>
					<div className="halfwidth p-16">
						<div className="mb-16">
							<div className="flex space-between">
								<h4 className="mb-8">User License Permissions</h4>
								<div className="textOnlyButton" onClick={addUserLicenseRuleSet} data-testid="dashboard-add-user-license-rule-set">+ add</div>
							</div>
							{dashboardDetails.userLicenseRuleSets.value && !!dashboardDetails.userLicenseRuleSets.value.length && (
								<div className="p-16 background-f9f9f9 createDashboard__ruleSets">
									{dashboardDetails.userLicenseRuleSets.value.map((userLicenseRuleSet, index) => (
										<UserLicenseRuleSetFields
											key={index}
											userLicenseRuleSet={userLicenseRuleSet}
											setUserLicenseRuleSet={(modifiedUserLicenseRuleSet: UserLicenseRuleSet) => setUserLicenseRuleSet(modifiedUserLicenseRuleSet, index)}
											removeUserLicenseRuleSet={() => removeUserLicenseRuleSet(index)} />
									))}
									{!dashboardDetails.userLicenseRuleSets.isValid && (<div data-testid="dashboard-user-license-rule-set-error">* all user license permission sets must have at least one license selected</div>)}
								</div>
							)}
						</div>
						<div className="mb-16">
							<div className="flex space-between">
								<h4 className="mb-8">Financial Access</h4>
								<div className="textOnlyButton" onClick={addRuleSet} data-testid="dashboard-add-rule-set">+ add</div>
							</div>
							{dashboardDetails.ruleSets.value && !!dashboardDetails.ruleSets.value.length && (
								<div className="p-16 background-f9f9f9 createDashboard__ruleSets">
									{dashboardDetails.ruleSets.value.map((ruleSet, index) => (
										<RuleSetFields
											key={index}
											ruleSet={ruleSet}
											setRuleSet={(ruleSet, index) => setRuleSet(ruleSet, index)}
											removeRuleSet={(index: number) => removeRuleSet(index)}
											index={index} />
									))}
									{!dashboardDetails.ruleSets.isValid && (<div data-testid="create-dashboard-rule-set-error">* please enter valid rules for all Financial Access sets</div>)}
								</div>
							)}
						</div>
						<div>
							<div className="flex space-between">
								<h4 className="mb-8">Access Level</h4>
							</div>
							<div className="p-16 background-f9f9f9 createDashboard__ruleSets">
								<AccessLevelFields 
									accessLevelRuleSet={dashboardDetails.accessLevelRuleSet}
									setAccessLevelRuleSet={(accessLevelRuleSet: AccessLevelRuleSet) => setDashboardDetails({...dashboardDetails, accessLevelRuleSet})} />
							</div>
						</div>
					</div>
				</div>
				{dashboardMetadata && <MetadataView metadata={dashboardMetadata} />}
			</div>
			<div className="flex align-items-center flex-end">
				<button className="cta mr-8" onClick={cancel} data-testid="create-dashboard-cancel">
					Cancel
				</button>
				<button
					className="cta cta--blue"
					onClick={async () => await saveDashboard()}
					data-testid="dashboard-save"
					disabled={!user?.isAdmin}
				>
					Submit
				</button>
			</div>
		</div>
	);
}

function isTitleValid(title: string) {
	return title !== '';
}

function isThumbnailValid(thumbnail: string) {
	return thumbnail !== '';
}

function isCategoriesValid(categories: Array<string>) {
	return categories.length > 0;
}

function isDashboardRegionLinksValid(dashboardRegionLinks: RegionDashboard[]) {
	return dashboardRegionLinks.some(dashboardLink => {
		return dashboardLink.region !== '' && dashboardLink.quicksightId !== '';
	});
}

function canAddUserLicenseRuleSet(userLicenseRuleSets: UserLicenseRuleSet[]) {
	return userLicenseRuleSets.every(userLicenseRuleSet => {
		return userLicenseRuleSet.businessIntelligence || userLicenseRuleSet.crm || userLicenseRuleSet.projects || userLicenseRuleSet.service || userLicenseRuleSet.retainers || userLicenseRuleSet.psa;
	})
}

function isRuleSetsValid(ruleSets: RuleSet[] | undefined) {
	const newRuleSet = {
		financialVisibility: false,
		costProfitContract: false,
		costProfitJob: false,
		costProfitGlobal: false,
		adminWithFinancialVisibility: false,
	};
	return !ruleSets?.some(ruleSet => deepEqual(ruleSet, newRuleSet, { strict: true }));
}

function isFiltersValid(filters: FilterDetails[]) {
	if (filters.length === 0) {
		return true;
	}

	const invalidDateRangeFilters = filters.every((filter) => {
		if (!filter.label) {
			return true;
		}
		if ('fromOffset' in filter) {
			const isInvalidFromOffset = isInvalidDateOffset(filter.fromOffset);
			const isInvalidToOffset = isInvalidDateOffset(filter.toOffset);
			const isToBeforeFromDate = isDateRangeInvalid({ fromOffset: filter.fromOffset.default, toOffset: filter.toOffset.default })
			const isInvalidDateRange = isDateRangeInvalid(filter.validRange);
			return isInvalidFromOffset || isInvalidToOffset || isToBeforeFromDate || isInvalidDateRange;
		}
		else if ('options' in filter) {
			return !isSelectOptionsValid(filter.options);
		}
		else {
			return false;
		}
	});

	if (invalidDateRangeFilters) {
		return false;
	}

	return checkFilterQuickSightParameterUniqueness(filters) && filters?.every(filter => {
		return validateFilter(filter);
	});
}

function checkFilterQuickSightParameterUniqueness(filters: FilterDetails[]) {
	let quicksightParameters = extractQuicksightParameters(filters);
	return quicksightParameters.length === new Set(quicksightParameters).size;
}

function extractQuicksightParameters(filters: FilterDetails[]) {
	let quicksightParameters: string[] = [];
	filters.forEach(
		filter => 'quicksightParameter' in filter ? quicksightParameters.push(filter.quicksightParameter) : quicksightParameters.push(filter.fromOffset.quicksightParameter, filter.toOffset.quicksightParameter)
	);
	return quicksightParameters;
}

function validateFilter(filter: FilterDetails) {
	if (filter.label === '' && hasNoQuicksightParameterSet(filter)) {
		return false;
	}

	return true;
}

function hasNoQuicksightParameterSet(filter: FilterDetails) {
if ('quicksightParameter' in filter) {
		return filter.quicksightParameter === '';
	}
	else {
		return filter.fromOffset.quicksightParameter === '' && filter.toOffset.quicksightParameter === '';
	}
}

function getInvalidEntries(dashboardDetails: DashboardDetails) {
	return Object.entries(dashboardDetails).filter((entry) => {
		return !entry[1].isValid && entry[1].isValid !== undefined;
	})
}

const newDashboardLink = {
	region: '',
	quicksightId: '',
}

const initialDashboardDetails: DashboardDetails = {
	title: {
		value: '',
		isValid: true,
	},
	isNew: false,
	description: '',
	thumbnail: {
		value: '',
		isValid: true,
	},
	categories: {
		value: [],
		isValid: true,
	},
	dashboardRegionLinks: {
		value: Array.from({ length: regions.length }, () => ({ ...newDashboardLink })),
		isValid: true,
	},
	userLicenseRuleSets: {
		value: [],
		isValid: true,
	},
	ruleSets: {
		value: undefined,
		isValid: true,
	},
	accessLevelRuleSet: {
		admin: false,
		professional: false,
		contractor: false,
		collaborator: false,
	},
	filters: {
		value: [],
		isValid: true,
	},
	parameters: [],
	isPrivate: false,
	deployments: [],
};

function getInitialDashboardDetails(gotDashboardDetails?: SavedDashboard): DashboardDetails {
	if (gotDashboardDetails) {
		initialDashboardDetails.title.value = gotDashboardDetails.title;
		initialDashboardDetails.isNew = gotDashboardDetails.isNew;
		initialDashboardDetails.description = gotDashboardDetails.description;
		initialDashboardDetails.thumbnail.value = gotDashboardDetails.thumbnail;
		initialDashboardDetails.categories.value = gotDashboardDetails.categories;
		initialDashboardDetails.dashboardRegionLinks.value = gotDashboardDetails.dashboardRegionLinks;
		initialDashboardDetails.userLicenseRuleSets.value = gotDashboardDetails.userLicenseRuleSets;
		initialDashboardDetails.ruleSets.value = gotDashboardDetails.ruleSets;
		initialDashboardDetails.accessLevelRuleSet = gotDashboardDetails.accessLevelRuleSet || initialDashboardDetails.accessLevelRuleSet;
		initialDashboardDetails.filters.value = gotDashboardDetails.filters;
		initialDashboardDetails.parameters = gotDashboardDetails.parameters || initialDashboardDetails.parameters;
		initialDashboardDetails.isPrivate = gotDashboardDetails.isPrivate;
		initialDashboardDetails.deployments = gotDashboardDetails.deployments;

		initialDashboardDetails.dashboardRegionLinks.value = addMissingDashboardRegionLinks(initialDashboardDetails.dashboardRegionLinks.value);
	}

	initialDashboardDetails.dashboardRegionLinks.value = updateDashboardRegionLinks(
		initialDashboardDetails.dashboardRegionLinks.value
	);

	return initialDashboardDetails;
}

function buildSaveDataStructure(details: DashboardDetails): NewDashboard {
	return {
		title: details.title.value.trim(),
		isNew: details.isNew,
		description: details.description.trim(),
		thumbnail: details.thumbnail.value.trim(),
		categories: details.categories.value,
		dashboardRegionLinks: details.dashboardRegionLinks.value,
		userLicenseRuleSets: details.userLicenseRuleSets.value,
		ruleSets: details.ruleSets.value || [],
		accessLevelRuleSet: details.accessLevelRuleSet || [],
		filters: details.filters.value,
		parameters: details.parameters,
		isPrivate: details.isPrivate,
		deployments: details.isPrivate ? details.deployments : [],
	};
}

function cancel() {
	if (window.confirm('Are you sure you want to cancel?')) {
		history.push('/');
	}
}

function updateDashboardRegionLinks(links: DashboardLink[]): DashboardLink[] {
	const sortedLinks = links.sort((a, b) => {
		const regionIndexA = regions.indexOf(a.region);
		const regionIndexB = regions.indexOf(b.region);
		return regionIndexA - regionIndexB;
	});
	return sortedLinks.map((link, index) => {
		if (link.region === '') {
			return { ...link, region: regions[index % regions.length] };
		}
		return link;
	});
}

function addMissingDashboardRegionLinks(links: DashboardLink[]): DashboardLink[] {
	const existingRegions = links.map(link => link.region);
	const missingRegions = regions.filter(region => !existingRegions.includes(region));
	const newLinks = missingRegions.map(region => ({
		region,
		quicksightId: ''
	}));
	return links.concat(newLinks);
}

export { isTitleValid, isThumbnailValid, isCategoriesValid, getInitialDashboardDetails, initialDashboardDetails };
